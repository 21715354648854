import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

// Style imports
import styles from '../styles/components/intro.module.css';

function Intro(props) {
	// Tower image
	const data = useStaticQuery(graphql`
		query IntroImages {
			tower: file(absolutePath: {}, relativePath: { eq: "tower.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	// Button
	let button = (
		<Link className="btn btn-primary" to={props.link}>
			{props.cta}
		</Link>
	);

	if (props.cta === '') {
		button = '';
	}

	return (
		<section className={styles.intro}>
			<div className={styles.content}>
				<div className={styles.image}>
					<Img fluid={data.tower.childImageSharp.fluid} alt="An illustration of a 5G tower." />
				</div>
				<h1>{props.title}</h1>
				<p>{props.description}</p>
				{button}
			</div>
		</section>
	);
}

export default Intro;
