import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// Import base styles
import '../styles/base.css';
import '../styles/overrides.css';

// Font Awesome import
import '../assets/fonts/font-awesome/css/all.css';

// Import components
import Header from '../components/header';
import Footer from '../components/footer';

const MainLayout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<Fragment>
			<Header siteTitle={data.site.siteMetadata.title} />
			<div id="layout">
				<div class="overlay" />
				<main>{children}</main>
				<Footer />
			</div>
		</Fragment>
	);
};

MainLayout.propTypes = {
	children: PropTypes.node.isRequired
};

export default MainLayout;
