import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

// Style imports
import styles from '../styles/components/footer.module.css';

function Footer() {
	return (
		<footer className={styles.footer}>
			<div className={styles.backToTop}>
				<AnchorLink className="btn" to="/#top" title="Home">
					<i class="fa fa-arrow-up" aria-hidden="true" />
					&nbsp;Back to top
				</AnchorLink>
			</div>
			<div className={styles.footer}>
				© {new Date().getFullYear()}, Built by
				{` `}
				<a href="https://adaptixcreative.com/">Adaptix</a>
			</div>
		</footer>
	);
}

export default Footer;
