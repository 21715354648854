import React, { Fragment, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

// Component imports
import Form from '../components/form.js';

// Style imports
import styles from '../styles/components/navbar.module.css';

function Navbar() {
	const [ toggleState, setToggleState ] = useState(styles.closed);
	const [ isActive, setActiveState ] = useState(false);

	function toggle() {
		setToggleState(toggleState === styles.closed ? styles.open : styles.closed);
		setActiveState(isActive === false ? true : false);
		document.getElementById('layout').classList.toggle('contactOpen');
	}

	// Nav logo image
	const data = useStaticQuery(graphql`
		query NavLogo {
			logoBig: file(absolutePath: {}, relativePath: { eq: "logo.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
			logoSmall: file(absolutePath: {}, relativePath: { eq: "logo-small.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	let navLogo = (
		<div className={styles.logo}>
			<Img
				className={styles.logoSmall}
				fluid={data.logoSmall.childImageSharp.fluid}
				alt="The Southern Edge Communications logo."
			/>
			<Img
				className={styles.logoBig}
				fluid={data.logoBig.childImageSharp.fluid}
				alt="The Southern Edge Communications logo."
			/>
		</div>
	);
	let buttonText = 'Contact';
	let navStyle = 'dark';
	if (toggleState === styles.open) {
		buttonText = 'Close';
		navStyle = 'light';
	}

	return (
		<div id="top" className={styles.navbar}>
			<div className={`${styles.navbarContainer} container`}>
				<div className={styles.left}>
					<AnchorLink to="/" title="Home">
						<div className={toggleState}>{navLogo}</div>
					</AnchorLink>
				</div>
				<div className={styles.right}>
					<ul className={`${styles[navStyle]}`}>
						<li>
							<AnchorLink to="/#services" title="What We Do">
								What We Do
							</AnchorLink>
						</li>
						<li>
							<AnchorLink to="/#about" title="Who We Are">
								Who We Are
							</AnchorLink>
						</li>
					</ul>
					<button className="btn btn-primary" onClick={toggle}>
						{buttonText}
					</button>
				</div>
			</div>
			<div className={styles.mobileMenu}>
				<div className={`${toggleState} ${styles.contact}`}>
					<div className={styles.contactContent}>
						<Form />
						<div className={styles.email}>
							Or email: <a href="mailto:email@so-edge.com">email@so-edge.com</a>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.desktopMenu}>
				<div className={`${toggleState} ${styles.contact}`}>
					<div className={styles.contactContent}>
						<Form />
						<div className={styles.email}>
							Or email: <a href="mailto:email@so-edge.com">email@so-edge.com</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar;
